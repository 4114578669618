<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>Map Movies
        <div class="card-header-actions"></div>
      </b-card-header>
      <b-card-body>
        <v-client-table
          class="VueTableCustom"
          :columns="columns"
          :data="data"
          :options="options"
          theme="bootstrap4"
          id="dataTable"
        >
          <div slot="LinkMovies" slot-scope="props" style="min-width: 400px">
            <CustomVueSelect
              wi
              :ref="'theVSelect'+props.row.LinkID"
              class="abs VueSelectCustom"
              :options.sync="linkMoviesList"
              label="Title"
              :clearable="false"
              @input="onChangeMovieDropdwon($event, props.row, props.index)"
              placeholder="Select Movie..."
            >
              <!-- :close-on-select="false" -->
            </CustomVueSelect>
          </div>

          <div slot="Action" slot-scope="props">
            <b-button
              :disabled="typeof props.row.ActiveStatus =='number' && props.row.ActiveStatus === 0"
              @click="onSubmitLinkMovie(props.row.ActiveStatus)"
              type="submit"
              variant="success"
              block
            >Submit</b-button>
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import vSelect from "vue-select";
import MovieService from "@/services/MovieService";
import MasterService from "@/services/MasterService";
import CustomVueSelect from "./MovieAside/Custom-Vue-Select";
import { Switch as cSwitch } from "@coreui/vue";

export default {
  name: "MapMovies",
  components: {
    vSelect,
    cSwitch,
    CustomVueSelect
  },
  data: function() {
    return {
      tempArray: [],
      linkMoviesList: [],
      selectedMovie: {
        FormatLanguageID: null,
        MovieMasterID: null,
        LinkID: null
      },
      columns: [
        "OrganizationName",
        "FilmName",
        "Format",
        "Language",
        "LinkMovies",
        "Action"
      ],
      data: [],
      options: {
        headings: {
          OrganizationName: "OrganizationName",
          FilmName: "MovieName",
          Format: "Format",
          Language: "Language",
          LinkMovies: "LinkMovies",
          Action: "Action"
        },
        sortable: ["OrganizationName", "FilmName", "Format", "Language"],
        filterable: ["OrganizationName", "FilmName", "Format", "Language"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        }
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  mounted() {
    this.getProviderMoviesList();
    this.getLinkMoviesList();
  },
  watch: {},
  methods: {
    getLinkMoviesList: function() {
      this.movieList = [];
      MovieService.getLinkMoviesList()
        .then(response => {
          const { data } = response;
          if (data.Status && data.MovieFormatLanguageList.length > 0) {
            this.linkMoviesList = data.MovieFormatLanguageList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getProviderMoviesList: function() {
      this.data = [];

      MovieService.getProviderMoviesList()
        .then(response => {
          const { data } = response;
          if (data.Status && data.ProviderMovieList.length > 0) {
            this.data = data.ProviderMovieList;
          } else {
            this.showMessageSnackbar(data.Message || "No Data Found");
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    onChangeMovieDropdwon: function(e, item, index) {
      console.log("e, item, index :", e, item);
      console.log("_index :", _index);
      console.log("this.data :", this.data[_index]);

      let _index = _.findIndex(this.data, { LinkID: item.LinkID });
      this.data[_index].ActiveStatus = {
        FormatLanguageID: e.FormatLanguageID,
        MovieMasterID: e.MovieMasterID,
        LinkID: item.LinkID
      };
      // this.tempArray.map((x)=>{
      //   if(tempArray)
      // })
      // this.data.map(x => {
      //   console.log("x.LinkID !== item.LinkID :", x.LinkID !== item.LinkID);
      //   if (x.LinkID !== item.LinkID) {
      //     x.ActiveStatus = 0;
      //     // console.log(
      //     //   "this.$refs.theVSelect :",
      //     //   this.$refs["theVSelect" + x.LinkID]
      //     // );
      //     // this.$refs["theVSelect" + x.LinkID].clearSelection();
      //   } else {
      //     x.ActiveStatus = item.LinkID;
      //   }
      // });
    },
    onSubmitLinkMovie: function(payload) {
      MovieService.onLinkMovie(payload)
        .then(response => {
          const { data } = response;
          this.getProviderMoviesList();
          data && data.Message ? this.showMessageSnackbar(data.Message) : "";
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.VueTableCustom .table-responsive {
  min-height: 500px;
}
.abs span {
  position: absolute;
}
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
.abs .dropdown-toggle .clear {
  display: none;
}
.abs .dropdown-menu > .highlight {
  width: 300px;
  display: block;
  max-width: 300px;
}
.abs .dropdown-menu li a {
  padding-left: 10px;
}
.abs .dropdown-menu > .highlight > a {
  display: block;
  width: 100%;
  max-width: 100%;
}

.abs.v-select {
  width: 400px;
}

.abs .dropdown-menu > .highlight > a {
  /*
     * required to override bootstrap 3's
     * .dropdown-menu > li > a:hover {} styles
     */
  /* background: #5897fb;
    color: #fff; */
  width: 100%;
  display: inline-block;
  color: #000000;
}
.abs .dropdown-menu > .highlight {
  width: 400px;
  display: block;
  max-width: 400px;
}

/* .abs .dropdown-menu li {
  width: 400px;
  background: yellow;
}

.abs .dropdown-menu li a {
  width: 400px;
}
.abs .dropdown-menu li a:hover {
  width: 400px;
  display: block;
  background-color: #20a8d8;
} */
</style>
